define(['app'], (app) => {

  const fastTrackModal = () => {
    const component = {};
    const GA_EVENT_CATEGORY = 'Fast Track Engagement';
    const GA_EVENT_ACTION = 'Clicked Fast Pass | More Info';

    const _init = (element) => {
      component.element = element;
      component.learnMoreButton = component.element.querySelector('.fastTrackModal_button');

      component.addEventListeners();
      return component;
    };

    const _addEventListeners = () => {
      component.learnMoreButton.addEventListener('click', component.learnMoreClicked);
    };

    const _learnMoreClicked = () => {
      app.publish('tracking/record', GA_EVENT_CATEGORY, GA_EVENT_ACTION);
    };

    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.learnMoreClicked = _learnMoreClicked;

    return component;
  };

  return fastTrackModal;
});
